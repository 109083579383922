import React from "react";
import NavbarBottom from "../navbar/NavbarBottom";
import NavbarTop from "../navbar/NavbarTop";

export default function Navbar() {
    return (
        <>
        <NavbarTop />
        <NavbarBottom />
        </>
    );
}